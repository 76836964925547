<template>
  <div
    v-if="breadcrumbs.length"
    class="breadcrumbs px-2 px-md-3 px-lg-4 py-3 d-flex flex-wrap align-center secondary"
  >
    <template
      v-for="(item, index) in breadcrumbs"
    >
      <component
        :key="`${$route.name}-item-${index}`"
        :is="typeof item === 'string' ? 'span' : 'router-link'"
        :to="typeof item === 'string' ? null : item.to"
        class="white--text text-decoration-none font-weight-bold text-h6"
      >
        {{ typeof item === 'string' ? item : item.text }}
      </component>

      <!-- Show an arrow in between the breadcrumbs -->
      <span
        v-if="index < (breadcrumbs.length - 1)"
        :key="`${$route.name}-arrow-${index}`"
        class="material-icons mx-2 white--text"
      >
        arrow_forward_ios
      </span>
    </template>

    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Breadcrumbs",

  computed: {
    breadcrumbs() {
      return this.$route?.meta?.breadcrumbs || []
    }
  }
}
</script>

<style lang="stylus" scoped>
// .breadcrumbs
//   background-color #b7b7b7
</style>
